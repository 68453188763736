<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="5"
      :loading="items.length === 0"
      item-key="name"
      multi-sort
      class="elevation-1"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from '@axios';

export default {
  name: 'TestList',

  components: {},
  props: {
    update: {
      type: Number,
      required: false,
      default: -1
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'name'
      },
      {
        text: 'Type',
        value: 'type'
      },
      {
        text: 'Created At',
        value: 'createdAt'
      }
    ],
    items: []
  }),
  computed: {
    valid() {
      return this.name.length && this.code.length;
    }
  },
  watch: {
    update: function () {
      this.list();
    }
  },
  mounted() {
    this.list();
  },
  methods: {
    list() {
      axios.get('/list-test').then((response) => {
        const items = response.data.result;
        this.items = items;
      });
    }
  }
};
</script>

<style lang="scss"></style>
