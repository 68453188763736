<template>
  <div>
    <v-form>
      <v-text-field v-model="test.name" label="Name" outlined></v-text-field>
      <v-text-field
        v-model="test.duration"
        type="number"
        label="Duration"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="test.eachQuestionPoint"
        label="Points for each question"
        outlined
      ></v-text-field>

      <div class="flex">
        <v-select
          v-model="selectedCategory"
          :items="categories"
          :menu-props="{ maxHeight: '400' }"
          item-value="_id"
          item-text="name"
          label="Category"
          hint="Choose question category"
          outlined
          persistent-hint
          style="margin-right: 8px"
        ></v-select>
        <v-text-field
          v-model="selectedCategoryCount"
          type="number"
          label="Count"
          hint="Amount of questions to be choosed from category"
          outlined
          style="margin-right: 8px"
        />
        <v-btn
          color="green"
          fab
          small
          style="margin-top: 6px"
          :disabled="
            !selectedCategory ||
            selectedCategoryCount < 1 ||
            selectedCategoryCount % 1 !== 0
          "
          @click="addQuestion"
        >
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </div>

      <div
        v-for="q of test.questions"
        :key="q.categoryId"
        class="flex"
        style="margin-bottom: 8px"
      >
        <div style="margin: auto 0; flex-grow: 1; margin-left: 12px">
          {{ q.count }} question{{ q.count > 1 ? 's' : '' }} from "{{
            q.categoryName
          }}"
        </div>
        <v-btn
          color="red"
          fab
          small
          style="margin: auto 0"
          @click="deleteChoice(q)"
        >
          <v-icon color="white">mdi-minus</v-icon>
        </v-btn>
      </div>

      <v-btn
        block
        elevation="3"
        :disabled="!valid"
        color="primary"
        large
        @click="createTest"
      >
        CREATE
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import axios from '@axios';

export default {
  name: 'TestCreate',
  components: {},
  data: () => ({
    categories: [],
    questionCount: 0,
    selectedCategory: null,
    selectedCategoryCount: 1,
    test: {
      name: '',
      duration: 0,
      eachQuestionPoint: 0,
      questions: []
    }
  }),
  computed: {
    valid() {
      if (!this.test.name) return false;
      if (this.test.duration <= 0 || this.test.duration % 1 !== 0) return false;
      if (
        this.test.eachQuestionPoint <= 0 ||
        this.test.eachQuestionPoint % 1 !== 0
      )
        return false;
      if (this.test.questions.length === 0) return false;
      return true;
    }
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    addQuestion() {
      this.test.questions.push({
        categoryId: this.selectedCategory,
        count: this.selectedCategoryCount,
        categoryName: this.categories.find(
          (c) => c._id === this.selectedCategory
        )?.name
      });
      this.selectedCategory = null;
      this.selectedCategoryCount = 1;
    },
    deleteChoice(q) {
      this.test.questions = this.test.questions.filter((_q) => _q.id !== q.id);
    },
    getCategories() {
      axios
        .get('/list-question-category')
        .then((response) => {
          if (response?.data?.result) {
            this.categories = response.data.result;
          }
        })
        .catch(() => {
          this.$toast.error('Error while getting question categories');
        });
    },
    createTest() {
      axios
        .post('/create-ability-test', {
          eachQuestionPoint: this.test.eachQuestionPoint,
          name: this.test.name,
          duration: this.test.duration,
          questions: this.test.questions
        })
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success('Test Oluşturuldu');
            this.$emit('onCreate');
          } else {
            this.$toast.error('Error while creating test');
          }
        })
        .catch(() => {
          this.$toast.error('Error while creating test');
        });
    }
  }
};
</script>

<style lang="scss"></style>
