<template>
  <page-layout>
    <v-card color="secondary">
      <v-tabs v-model="tab" background-color="tabber" color="primary" grow>
        <v-tab> List </v-tab>
        <v-tab> Create </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="p-2">
            <test-list :update="updateList" />
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="p-2">
            <test-create @onCreate="onCreate" />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout';
import TestList from '@/components/TestList';
import TestCreate from '@/components/TestCreate';

export default {
  name: 'Test',

  components: {
    PageLayout,
    TestList,
    TestCreate
  },
  data: () => ({
    tab: null,
    updateList: 0
  }),
  methods: {
    onCreate() {
      this.updateList++;
    }
  }
};
</script>
